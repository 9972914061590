

export function hierarchyTree(state, action) {
  let tree = reset();
  switch (action.type) {
    case 'UPDATE':
      return {...state, data: action.data}
    case 'RESET' :
      return {...state, data:tree}
    default :
      return {...state, data: tree}
  };
}

function reset() {
  let tree = [];
  tree._0 = {};
  tree._11 = {};
  tree._12 = {};
  tree._211 = {};
  tree._212 = {};
  tree._221 = {};
  tree._222 = {};
  return tree;
}