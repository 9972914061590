import {createStore, applyMiddleware} from "redux";
import thunkMiddleware from "redux-thunk";
// import {createLogger} from "redux-logger";
import jwtDecode from "jwt-decode";
import rootReducer from "../_reducers";

// const loggerMiddleware = createLogger();
const checkTokenExpirationMiddleware = store => next => action => {
  const token = JSON.parse(localStorage.getItem("user"));
  if (!token || token === "") {
    return;
  } else if (jwtDecode(token).exp < Date.now() / 1000) {
    next(action);
    localStorage.removeItem("user");
  }
  next(action);
};

export const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
    checkTokenExpirationMiddleware
    // loggerMiddleware
  )
);
