import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import { PrivateRoute } from "./_component";
import { configConstants } from "./_constants";
import "./App.scss";

let role;
const userJson = localStorage.getItem("userData");
if(userJson !== 'undefined'){
  const userData = JSON.parse(userJson);
  role = userData && userData.Role && userData.Role.Nama ? userData.Role.Nama : null;
}

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
});

// Pages
const SuperLogin = Loadable({
  loader: () => import("./views/Pages/SuperUser"),
  loading
});

// Pages Logout
const Logout = Loadable({
  loader: () => import("./views/Pages/Logout"),
  loading
});

const EMitraCardDownload = Loadable({
  loader: () => import("./views/EMitraCard/EMitraCardDownload"),
  loading
});

// Register with ReferralCode
const RegisterReferral = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading
});

// const ForgotPassword = Loadable({
//   loader: () => import("./views/Pages/ForgotPassword"),
//   loading
// });

// const EMitraDownload = Loadable({
//   loader: () => import("./views/EMitraCard/EMitraCardDownload"),
//   loading
// });

// const ResetPassword = Loadable({
//   loader: () => import("./views/Pages/ResetPassword"),
//   loading
// });

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading
});

const Close = Loadable({
  loader: () => import("./views/Pages/ClosePage"),
  loading
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
});

// Konfirmasi
const Konfirmasi = Loadable({
  loader: () => import(`./views/Pendaftaran/Daftar/Konfirmasi`),
  loading
});

// Invoice
const Invoice = Loadable({
  loader: () => import("./views/Pendaftaran/Daftar/Invoice"),
  loading
});

// EMitraCard
const EMitraCard = Loadable({
  loader: () => import("./views/Pendaftaran/Daftar/EMitraCard"),
  loading
});

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={`${configConstants.PREFIX_URL}/login`} name="Login Page" component={Login} />
          <Route exact path={`${configConstants.PREFIX_URL}/logout`} name="Logout Page" component={Logout} />
          <Route exact path={`${configConstants.PREFIX_URL}/e-mitra-download/:nopolis/`} name="E Mitra Download" component={EMitraCardDownload} />
          {/* <Route exact path=`${configConstants.PREFIX_URL}/forgot-password" name="Forgot Password Page" component={ForgotPassword} />
          <Route exact path=`${configConstants.PREFIX_URL}/reset-password" name="Reset Password Page" component={ResetPassword} /> */}
          {/* <Route exact path=`${configConstants.PREFIX_URL}/e-mitra-download" name="E Mitra Download" component={EMitraDownload} /> */}
          {/* <Route
            exact
            path={`${configConstants.PREFIX_URL}/register`}
            name="Register Page"
            component={Register}
          /> */}
          <Route
            exact
            path={`${configConstants.PREFIX_URL}/reff/:refferalcode`}
            name="Register Page With Refferal"
            component={RegisterReferral}
          />
          <Route
            exact
            path={`${configConstants.PREFIX_URL}/superlogin/:id/:token`}
            name="Super Login Pages"
            component={SuperLogin}
          />
          <Route exact path={`${configConstants.PREFIX_URL}/close`} name="Close Page" component={Close} />
          <Route exact path={`${configConstants.PREFIX_URL}/404`} name="Page 404" component={Page404} />
          <Route exact path={`${configConstants.PREFIX_URL}/500`} name="Page 500" component={Page500} />
          <Route
            exact
            path={`${configConstants.PREFIX_URL}/${role === 'basic' ? "upgrade-member" : "pendaftaran"}/konfirmasi`}
            name="Konfirmasi"
            component={Konfirmasi}
          />
          <Route
            exact
            path={`${configConstants.PREFIX_URL}/pendaftaran/invoice`}
            name="Invoice"
            component={Invoice}
          />
          <Route
            exact
            path={`${configConstants.PREFIX_URL}/pendaftaran/e-mitra-card`}
            name="E-Mitra Card"
            component={EMitraCard}
          />
          <PrivateRoute path={`${configConstants.PREFIX_URL}/`} name="Home" component={DefaultLayout} />
          <Redirect from="/" to={`${configConstants.PREFIX_URL}`} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
