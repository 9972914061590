import {combineReducers} from "redux";

import {authentication} from "./authentication.reducer";
import {alert} from "./alert.reducer";
import {hierarchyTree} from "./tree.reducer";

const rootReducer = combineReducers({
  authentication,
  alert,
  hierarchyTree
});

export default rootReducer;
